@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/material-icons/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(./fonts/material-icons/MaterialIcons-Regular.woff2) format('woff2'), url(./fonts/material-icons/MaterialIcons-Regular.woff) format('woff'), url(./fonts/material-icons/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: local('Roboto-Bold'), url('./fonts/roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: bold;
  src: local('Roboto-BoldItalic'), url('./fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Medium'), url('./fonts/roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  src: local('Roboto-LightItalic'), url('./fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: lighter;
  src: local('Roboto-Light'), url('./fonts/roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto-Thin'), url('./fonts/roboto/Roboto-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Allotrope';
  font-style: normal;
  font-weight: normal;
  src: local('Allotrope-400'), url('./fonts/allotrope/allotrope-400.otf') format('opentype');
}
@font-face {
  font-family: 'Allotrope';
  font-style: normal;
  font-weight: normal;
  src: local('Allotrope-500-italic'), url('./fonts/allotrope/allotrope-500-italic.otf') format('opentype');
}
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6,
header,
thead,
th {
  font-family: "Allotrope", sans-serif;
}
#app,
#app > div,
#app > div > div {
  height: 100%;
}
button {
  font-family: "Allotrope", sans-serif;
}
.a0-primary {
  background-color: #1f58a5 !important;
}
.a0-icon-badge:before {
  content: '' !important;
}
.tableRowWithoutCheckbox > td:first-child {
  display: none;
}
.notSelectable > td:first-child > div {
  opacity: 0.3;
}

@keyframes loadingAnimation {
  0%   { transform: rotate(0deg); }
  49.99999999%  { transform: rotate(0deg); }
  50%  { transform: rotate(180deg); }
  80%  { transform: rotate(180deg); }
  100% { transform: rotate(0deg); }
}

@keyframes spin {
  0%  {
    transform: rotate(360deg)
  }
  100% {
    transform: rotate(0deg),
  }
}
